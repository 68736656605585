<template>
    <div>
        <q-header elevated  class="text-primary ColoreBarra">
            <q-toolbar>
                <img :src="require('@/images/albero-natale.png')" style="max-width: 50px; max-height: 50px;" v-show="isNatale">
                <div class="NomePiattaforma" style="padding-left: 16px;">
                    ABY<i>Next </i>
                </div>

                <q-space />

                <q-btn-toggle
                    v-model="BrowserViewMode"
                    toggle-color="red-9"
                    color="grey"
                    @click="CambiaSfondoProgramma()"
                    :options="[
                        {label: 'Light', value: 'Light'},
                        {label: 'Dark', value: 'Dark'}
                    ]"
                />

                <q-btn color="grey" text-color="white" label="Esci"
                    icon="mdi-power"
                    @click="esciDalProgramma()"
                />

            </q-toolbar>

        </q-header>
        <br><br><br><br>
        <div class="justify-center" align="center" style="margin-bottom: -40px;">
            <q-btn-toggle
                v-model="sezione_visualizzata"
                text-color="white"
                toggle-color="red-9"
                color="blue-grey"
                :options="sezioni"
                spread
                @click="cambiaSezione(sezione_visualizzata)"
            />
        </div>
    </div>

</template>

<script>

import { mapFields } from "vuex-map-fields"
import auth from "@/libs/auth";
import { mapActions } from "vuex";
// import { Dark } from 'quasar';

export default {
    name: "PiattaformaRami_Header",
    data() {
        return {
            sezioni: [
                {label: 'Preventivi', value: "Preventivi"},
                {label: 'Quotazioni', value: "Quotazioni"},
                {label: 'Clienti', value: 'Clienti'},
                {label: 'Pratiche', value: 'Pratiche'},
                // {label: 'Fatture', value: 'Fatture'},
                {label: 'Simpli', value: 'Simpli'},
                {label: 'Estrazioni', value: 'Estrazioni'}


           //    {label: 'Analisi del portafoglio', value: "Statistiche"}
            ],
            BrowserViewMode: "Light"
        }
    },
    methods: {
        ...mapActions({
            resetFormPreventivo: "formPreventivo/resetFormPreventivo",

        }),
        async cambiaSezione(sezione) {

            // await this.$store.dispatch("formPreventivo/resetStatoIniziale");
            switch(sezione) {
                case "Preventivi":
                case "Quotazioni":
                case "Clienti":
                case "Pratiche":
                case "Estrazioni":
                case "Fatture":
                case "Simpli":
               // case "Statistiche":
                    // TODO - Deve resettare tutti i dati delle quotazioni e del formPreventivo
                    //await this.resetFormPreventivo();
                    this.$router.push({name: sezione});
                break;
            }

        },
        esciDalProgramma() {
            auth.logout();
            window.location = process.env.VUE_APP_ABYWAY_URL; //"http://www.abyway.it/dashboard";
        },
        CambiaSfondoProgramma() {
            if (this.BrowserViewMode === "Dark") {
                // Dark.set(true);
                this.$store.commit('localStorage/QUASAR_DARK_MODE', true);

            } else {
                // Dark.toggle();
                this.$store.commit('localStorage/QUASAR_DARK_MODE', false);
            }

        }
    },
    computed: {
        ...mapFields("", {
            sezione_visualizzata: "sezione_visualizzata",
        }),
        isNatale() {
            var dt = new Date();
            let mese = dt.getMonth();

            return (mese === 11);
        }
    },
    mounted() {
        if(this.$store.getters["localStorage/dark_mode"] ?? false) {
            this.BrowserViewMode = "Dark";
        } else {
            this.BrowserViewMode = "Light";
        }

    }
}
</script>

<style>
    .NomePiattaforma {
        padding-top: 7px;
        font-family: arial;
        /*font-style: italic;*/
        font-size: 32px;
        color: #fff;
    }

    .ColoreBarra {
        /*background-color: rgba(125,185,232,1);*/
        background: -moz-linear-gradient(top, rgba(30,87,153,1) 0%, rgba(125,185,232,1) 100%);
        background: -webkit-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(125,185,232,1) 100%);
        background: linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(125,185,232,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#007db9e8',GradientType=1 );
    }
</style>
